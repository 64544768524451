import React, { useEffect, useState } from "react";
import MyContext from "./Mycontexts";

const MyProvider = ({ children }) => {
    const [signUser, setSignUser] = useState(null);
    useEffect(() => {
        const storedUser = JSON.parse(sessionStorage.getItem("userSession") || "{}");
        if (storedUser) {
            setSignUser(storedUser)
        }
    }, []);

    return (
        <MyContext.Provider value={{ signUser, setSignUser }}>
            {children}
        </MyContext.Provider>
    );
};

export default MyProvider;