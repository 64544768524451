import React from 'react'

function PlatformIntro() {
  return (
    <div>
      <section>
        <div className="container mt-4 mb-3">
            <div className="row d-flex justify-content-center">
                <div className="col-md-10 text-center">
                    <h2>What Is This Platform For?</h2>
                    <h3 style={{fontSize: '22px', color: 'var(--secondary-color)'}}>An introduction to Pakistan's leading LMS platform</h3>
                    <p>In the vast expanse of the online world, Mentors Academia leading online learning platform, offering a unique educational platform that harmoniously integrates Islamic teachings with science and Digital technology. This pioneering initiative seeks to empower students with a comprehensive understanding of the world, fostering a generation of individuals who are well-versed in both spiritual and scientific knowledge By combining expert mentorship, innovative teaching methods, and a focus on practical application, this platform sets a new age standard for transforming education in the digital age.</p>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default PlatformIntro
