import React from 'react'
import AllLessons from '../../adminside/Lesson/AllLessons'

export default function InstructorLessons() {
    return (
        <>
            <AllLessons />
        </>
    )
}
