import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../contexts/Mycontexts";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

import Offcanvas from "react-bootstrap/Offcanvas";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CourseContext from "../../contexts/CourseContext";
import EnrolledContext from "../../contexts/EnrolledContext";


export default function UserProfileCourses() {
  const { allCourse, CourseById, getCourse, getCourseCat } = useContext(CourseContext);
  const { EnrolRequests } = useContext(EnrolledContext)
  const { signUser } = useContext(MyContext);
  const [category, setCategory] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [enroll, setEnroll] = useState({
    studentId: "",
    courseId: "",
    description: "",
  });

  const [searchName, setSearchName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");



  const Enrollment = async (e) => {
    e.preventDefault();
    const userNameError = document.getElementById("userNameError")
    const userTitleError = document.getElementById("userTitleError")
    const userDescError = document.getElementById("userDescError")

    let errorHandle = false;

    const { courseId, studentId, description } = enroll;

    if (!studentId) {
      userNameError.innerText = "Select course name please"
      errorHandle = true
    } else {
      userNameError.innerText = ""
    }
    if (!courseId) {
      userTitleError.innerText = "Select Course title please"
      errorHandle = true
    } else {
      userTitleError.innerText = ""
    }
    if (errorHandle) {
      return;
    }
    await fetch("https://accademia-backend.vercel.app/api/auth/enrollments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId, studentId, description }),
    });

    setEnroll({
      studentId: "",
      courseId: "",
      description: "",
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Enrollment request send successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    EnrolRequests()
  };
  const onchange = (e) => {
    setEnroll({ ...enroll, [e.target.name]: e.target.value });
  };


  const getCategory = async () => {
    const res = await fetch(
      "https://accademia-backend.vercel.app/api/auth/getcategory",
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setCategory(data);
  };
  useEffect(() => {
    getCategory();
  }, []);

  const showDetail = (simpleid) => {
    CourseById(simpleid)
    handleShow()
  }
  return (
    <div>
      <section className="courses-section py-4 bg-white">
        <div className="container">
          <div className="row g-4">
            {allCourse &&
              allCourse
                .filter((course) =>
                  course.title.toLowerCase().includes(searchName.toLowerCase())
                )
                .filter((course) => course.categoryId.includes(searchCategory))
                .reverse()
                .map((data) => {
                  return (
                    <div className="col-md-6">
                      <div className="card courses">
                        {/* <div className="course-content">
                      <img src={data.image ? data.image : frontendDeveloper} alt="" className="img-fluid" style={{ height: "200px", width: "100%" }} />
                    </div> */}
                        <div className="course-title p-4">
                          <p className="bold-p">
                            {data.title.length > 20
                              ? data.title.slice(0, 20) + "..."
                              : data.title}
                          </p>

                          <p>
                            Instructor Name: <b>{data.instructorName}</b>
                          </p>
                          <p>
                            Duration: <b>{data.duration}</b>
                          </p>
                        </div>
                        <div className="join mb-2">
                          {/* <Link to={`/course-details/${data.title.replace(/ /g, '-')}`}>
                        <button className="first-button">View Contents</button>
                      </Link> */}

                          <Button className="first-button" onClick={() => showDetail(data._id)} >
                            View Details
                          </Button>

                          <button className="second-button float-end"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => CourseById(data._id)}
                          >
                            Enroll Now
                          </button>

                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </section>

      {/* Course Details Modal */}

      <Offcanvas style={{ width: '80%' }} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1>{getCourse.title}</h1>
          <div className="overview bg-white p-3 mb-4">
            <h2>Course Overview</h2>
            <p style={{ fontWeight: "initial" }}>{getCourse.description}</p>

            <ul style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Instructor:{" "}
                <strong> {getCourse.instructorName} </strong>
              </li>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Duration: <strong> {getCourse.duration} </strong>
              </li>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Category:{" "}
                <strong> {getCourseCat.category} </strong>
              </li>
              {(getCourse.days && getCourse.days !== "undefined") && (

                <li>
                  <CheckCircleIcon
                    className="list-icon"
                    style={{ color: "var(--secondary-color)" }}
                  />
                  &nbsp;Classes In A Week:{" "}
                  <strong> {getCourse.days} </strong>
                </li>
              )}
              {(getCourse.timeSlot && getCourse.timeSlot !== "undefined") && (

                <li>
                  <CheckCircleIcon
                    className="list-icon"
                    style={{ color: "var(--secondary-color)" }}
                  />
                  &nbsp;Class Timings:{" "}
                  <strong> {getCourse.timeSlot} </strong>
                </li>
              )}
            </ul>

            <div className="course-img">
              <img src={getCourse.image} alt="" className="img-fluid" />
            </div>
            <div className="row">
              {getCourse.learning && (
                <div className="col-md-5 listing mt-4">
                  <div className="learning-outcomes py-2">
                    <h3>{getCourse.moduleName1}</h3>
                    <ul
                      style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                    >
                      {getCourse.learning &&
                        getCourse.learning.split("\n").map((line, lineindex) => {
                          return (
                            <li key={lineindex}>
                              <CheckCircleIcon className=" user-course-detail-icon" />
                              &nbsp;{line}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}

              {getCourse.content && (
                <div className="col-md-5 listing mt-4">
                  <div className="learning-outcomes py-2">
                    <h3>{getCourse.moduleName2}</h3>
                    <ul
                      style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                    >
                      {getCourse.content &&
                        getCourse.content.split("\n").map((line, lineindex) => {
                          return (
                            <li key={lineindex}>
                              <CheckCircleIcon className="user-course-detail-icon" />
                              &nbsp;{line}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}


            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>


      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enroll Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={Enrollment}>
                <select
                  className="form-control mt-3"
                  id="Name"
                  name="studentId"
                  value={enroll.studentId}
                  onChange={onchange}
                >
                  <option value="">Select Name</option>
                  {signUser && (
                    <option value={signUser._id}>{signUser.name}</option>
                  )}
                </select>
                <div id="userNameError" className="text-danger"></div>
                <select
                  className="form-control mt-3"
                  id="course Name"
                  name="courseId"
                  value={enroll.courseId}
                  onChange={onchange}
                >
                  <option value="">Select Course</option>
                  <option value={getCourse._id}>{getCourse.title}</option>
                </select>
                <div id="userTitleError" className="text-danger"></div>
                <textarea
                  className="form-control mt-3"
                  name="description"
                  value={enroll.description}
                  onChange={onchange}
                  type="text"
                  placeholder="Description"
                  rows={6}
                />
                <div id="userDescError" className="text-danger"></div>
                <button type="submit" className="btn btn-primary mt-3">
                  Enroll Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}
