import React, { useEffect, useState } from 'react'
import LessonContext from './LessonContext'
import Swal from 'sweetalert2'

function LessonProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [lessons, setLessons] = useState([])
    const [lessonById, setLessonById] = useState([])

    const allLessons = async () => {
        const res = await fetch("https://accademia-backend.vercel.app/api/lesson/allLessons", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setLessons(data)
    }
    const getById = async (id) => {
        const res = await fetch(`https://accademia-backend.vercel.app/api/lesson/get/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        console.log(data);
        setLessonById(data)
    }
    const delById = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(
                `https://accademia-backend.vercel.app/api/lesson/delete/${id}`,
                {
                    method: "delete",
                }
            );
            allLessons();
        }
    }

    useEffect(() => {
        allLessons();
    }, [])


    return (
        <LessonContext.Provider value={{ lessonCourseId, setLessonCourseId, lessons, getById, lessonById, allLessons, delById, setLessonById }}>
            {children}
        </LessonContext.Provider>
    )
}

export default LessonProvider
