import { React, useContext, useState } from 'react'
import LessonContext from '../../contexts/LessonContext'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import LessonProvider from '../../contexts/LessonProvider';

function AddLessonModal({ courseIdd }) {
  const [lgShow, setLgShow] = useState(false);
  const { lessonCourseId, allLessons, setLessonCourseId } = useContext(LessonContext)
  const [lesson, setLesson] = useState({
    title: "", description: "", videoUrls: [""]
  })

  const fieldValidation = (fields) => {
    const titleError = document.getElementById("titleError")
    const descriptionError = document.getElementById("descriptionError")
    const urlError = document.getElementById("urlError")
    let validationError = false;

    if (!fields.title) {
      titleError.innerText = "Lesson Title Is Mandatory"
      validationError = true;
    }
    else {
      titleError.innerText = ""
    }
    if (!fields.description) {
      descriptionError.innerText = "Add Description First"
      validationError = true;
    }
    else {
      descriptionError.innerText = ""
    }
    if (!fields.videoUrls[0] === "") {
      urlError.innerText = "Add Description First"
      validationError = true;
    } else {
      urlError.innerText = ""
    }
    return validationError;
  }

  // add Lesson
  const addLesson = async (e) => {
    e.preventDefault()
    const { title, description, videoUrls } = lesson
    const courseId = lessonCourseId;
    const hasError = fieldValidation(lesson)
    if (hasError) {
      return;
    }
    const res = await fetch("https://accademia-backend.vercel.app/api/lesson/addLesson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ title, description, videoUrls, courseId })
    })
    const data = await res.json()
    console.log(data)
    if (!res.ok) {
      console.log(`Error: ${res.status} - ${res.statusText}`);
    }
    else {
      setLesson({
        title: "",
        description: "",
        videoUrls: [""]
      })
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Lesson has been added",
        showConfirmButton: false,
        timer: 1500
      });
      allLessons()
    }

  }

  const onChangeLesson = (e, index) => {
    if (e.target.name === "videoUrls") {
      const updateVideoUrls = [...lesson.videoUrls]
      updateVideoUrls[index] = e.target.value
      setLesson({ ...lesson, videoUrls: updateVideoUrls })
    } else {
      setLesson({ ...lesson, [e.target.name]: e.target.value });
    }
  };
  const addUrlField = () => {
    setLesson({ ...lesson, videoUrls: [...lesson.videoUrls, ""] })
  }
  const openModalAddId = () => {
    setLessonCourseId(courseIdd)
    setLgShow(true)
  }
  return (
    <div>
      <div id="errohandle"></div>
      <Button onClick={() => openModalAddId()}>Add Lesson</Button>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h5>Add New Lesson</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addLesson}>
            <div className="mb-3">
              <input type="text" className="form-control" placeholder="Lesson Title" name="title" value={lesson.title} onChange={onChangeLesson} />
              <div className="text-danger" id="titleError"></div>
            </div>
            <div className="mb-3">
              <textarea className="form-control" placeholder="Lesson Description" name="description" value={lesson.description} onChange={onChangeLesson}></textarea>
              <div className="text-danger" id="descriptionError"></div>
            </div>
            {lesson.videoUrls && lesson.videoUrls.map((url, index) => {
              return <div className="mb-3">
                <input type="text" className="form-control" placeholder={`Your YouTube Video URL ${index + 1}`} name="videoUrls" value={url} onChange={(e) => onChangeLesson(e, index)} />
              </div>
            })}
            <div className="text-danger" id="urlError"></div>


            <div className="mb-3">
              <button type="button" className="second-button" onClick={() => addUrlField()}><i class="fas fa-plus" style={{ fontSize: '15px' }}></i>&nbsp;Add More Videos</button>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="submit" className=" first-button">Add Lesson</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddLessonModal
