import React, { useEffect, useState } from 'react'
import EnrolledContext from './EnrolledContext'

function EnrolledProvider({ children }) {


    const [request, setRequest] = useState([])
    const EnrolRequests = async () => {
        const res = await fetch("https://accademia-backend.vercel.app/api/enrollCourse/enrlRequests", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setRequest(data)
    }
    useEffect(() => {
        EnrolRequests()
    }, [])

    const AcceptRequest = async (id) => {
        await fetch(`https://accademia-backend.vercel.app/api/enrollCourse/acceptStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        })
        EnrolRequests()
    }
    const RejectRequest = async (id) => {
        await fetch(`https://accademia-backend.vercel.app/api/enrollCourse/rejectStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        })
        EnrolRequests()
    }

    return (
        <EnrolledContext.Provider value={{ request, AcceptRequest, RejectRequest, EnrolRequests }}>
            {children}
        </EnrolledContext.Provider>
    )
}

export default EnrolledProvider
