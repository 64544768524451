import {React, useState, useEffect} from 'react'
import CategoryContext from './CategoryContext';
import Swal from 'sweetalert2';

function CategoryProvider({children}) {

    const [category, setCategory] = useState([]);
    const [AllCategory, setAllCategory] = useState([])

      // get all courses
      const allCategory = async () => {
        await fetch("https://accademia-backend.vercel.app/api/category/getcategory", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setAllCategory(data))
    }

    const getOnlyCategory = async () => {
        const res = await fetch(
          "https://accademia-backend.vercel.app/api/category/getOnlyCategory",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        setCategory(data);
      };


      const deleteCategory = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "This course category has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(`https://accademia-backend.vercel.app/api/category/delcategory/${id}`, {
                method: "delete"
            })
            allCategory()
        }
    }

      useEffect(() => {
        getOnlyCategory();
        allCategory();
      }, []);



  return (
    <CategoryContext.Provider value={{category, AllCategory, allCategory, deleteCategory}}>
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider;
