import React, { useContext, useState } from "react"
import Swal from "sweetalert2"
import MyContext from "../../contexts/Mycontexts"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InstructorContext from "../../contexts/InstructorContext";
import TeacherModals from "./TeacherModals";

export default function Addteacher() {
    const { signUser } = useContext(MyContext)
    const { allTeacher, allTeachers, deleteTeacher, TeacherByIdFn } = useContext(InstructorContext)

    const [pageNmber, setPageNumber] = useState(1)
    const [searchOption, setSearchOption] = useState("")
    const [Teacher, setTeacher] = useState({
        name: "", email: "", number: "", qualification: "", experience: "", description: "", image: "", website: "", youtube: "", instaUrl: "", fbUrl: "", twitterUrl: "", experties: ""
    })

    const missingFieldsError = (fields) => {
        const nameError = document.getElementById("nameError")
        const emailError = document.getElementById("emailError")
        const numberError = document.getElementById("numberError")
        const expertiesError = document.getElementById("expertiesError")
        const qualiError = document.getElementById("qualiError")
        const expeError = document.getElementById("expeError")
        const descriptionError = document.getElementById("descriptionError")
        let emptyFieldError = false
        if (!fields.name) {
            nameError.innerText = "please enter name"
            emptyFieldError = true
        } else {
            nameError.innerText = ""
        }
        if (!fields.email) {
            emailError.innerText = "please enter email"
            emptyFieldError = true
        } else if (!fields.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emailError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emailError.innerText = ""
        }
        if (!fields.number) {
            numberError.innerText = "please enter number"
            emptyFieldError = true
        } else {
            numberError.innerText = ""
        }
        if (!fields.qualification) {
            qualiError.innerText = "please add qualification"
            emptyFieldError = true
        } else {
            qualiError.innerText = ""
        }
        if (!fields.experience) {
            expeError.innerText = "please add experience"
            emptyFieldError = true
        } else {
            expeError.innerText = ""
        }
        if (!fields.experties) {
            expertiesError.innerText = "please add experties"
            emptyFieldError = true
        } else {
            expertiesError.innerText = ""
        }
        if (!fields.description) {
            descriptionError.innerText = "please add description"
            emptyFieldError = true
        } else {
            descriptionError.innerText = ""
        }
        return emptyFieldError;
    }

    // add Teacher
    const addTeacher = async (e) => {
        e.preventDefault()
        const emailError = document.getElementById("emailError")
        const { name, email, number, qualification, experience, description, image, website, youtube, instaUrl, fbUrl, twitterUrl, experties } = Teacher

        const hasError = missingFieldsError(Teacher)
        if (hasError) {
            return;
        }

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("qualification", qualification);
        formData.append("image", image);
        formData.append("number", number);
        formData.append("experience", experience);
        formData.append("experties", experties);
        formData.append("description", description);
        formData.append("website", website);
        formData.append("youtube", youtube);
        formData.append("userId", signUser._id);
        formData.append("fbUrl", fbUrl);
        formData.append("instaUrl", instaUrl);
        formData.append("twitterUrl", twitterUrl);

        const res = await fetch("https://accademia-backend.vercel.app/api/teacher/addTeacher", {
            method: "POST",
            body: formData
        })
        const data = await res.json()
        if (data.message === "Instructor with this email already exists") {
            emailError.innerText = "Instructor with this email already exists"
        } else {
            setTeacher({
                name: "", email: "", number: "", qualification: "", experience: "", description: "", image: "", website: "", youtube: "", instaUrl: "", fbUrl: "", twitterUrl: "", experties: ""
            })
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Teacher has been created",
                showConfirmButton: false,
                timer: 1500
            });
            allTeachers()
        }

    }

    const onchange = (e) => {
        if (e.target.files) {
            setTeacher({ ...Teacher, image: e.target.files[0] })
        } else {
            setTeacher({ ...Teacher, [e.target.name]: e.target.value })
        }
    }

    // pagination implement
    const teacherPerPage = 16
    const lastTeacherIndex = teacherPerPage * pageNmber
    const firstTeacherIndex = lastTeacherIndex - teacherPerPage
    const teachersData = allTeacher && allTeacher.filter(teacher => teacher.status === "Approved").filter(teacher => teacher.name.toLowerCase().includes(searchOption.toLowerCase()) || teacher.email.toLowerCase().includes(searchOption.toLowerCase()) || teacher.number.includes(searchOption)).slice(firstTeacherIndex, lastTeacherIndex)

    return (
        <div className="container mt-3">
            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-8">
                    <input type="text" className="form-control" value={searchOption} onChange={(e) => setSearchOption(e.target.value)} placeholder="Search By name/email/number" />
                </div>
                <div className="d-flex justify-content-end col-md-7 col-4">
                    <button className="btn btn-primary mb-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="fas fa-plus"></i> Add Teacher</button>
                </div>
                <div className="col-md-11">
                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Number</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teachersData && teachersData.map((teacher) => {
                                    return <tr>
                                        <td>{teacher.name}</td>
                                        <td>{teacher.email}</td>
                                        <td>{teacher.number}</td>
                                        <td>{teacher.status}</td>
                                        <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={() => TeacherByIdFn(teacher._id)}></i>
                                            <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" onClick={() => TeacherByIdFn(teacher._id)}></i>
                                            <i className="fa fa-trash" onClick={() => deleteTeacher(teacher._id)}></i>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end my-3">
                        {[...Array(Math.ceil(allTeacher.length / teacherPerPage)).keys()].map((number) => {
                            return <button key={number + 1} className="btn btn-primary mx-1" onClick={() => setPageNumber(number + 1)}>{number + 1}</button>
                        })}
                    </div>
                </div>
            </div>

            {/* Teacher modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add Teacher</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addTeacher}>
                                <div className="container mb-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input className="form-control mt-3" type="text" placeholder="Name" name="name" value={Teacher.name} onChange={onchange} />
                                            <div id="nameError" className="text-danger"></div>
                                            <input className="form-control mt-3" type="text" placeholder="Experties" name="experties" value={Teacher.experties} onChange={onchange} />
                                            <div id="expertiesError" className="text-danger"></div>
                                            <PhoneInput
                                                className="mt-3 phonInput"
                                                inputStyle={{
                                                    border: "none",
                                                    boxShadow: "none"
                                                }}
                                                country={'pk'}
                                                name="number"
                                                value={Teacher.number}
                                                onChange={(value, country, event, formattedValue) => {
                                                    onchange({
                                                        target: {
                                                            name: 'number',
                                                            value: formattedValue
                                                        }
                                                    });
                                                }}
                                            />
                                            <div id="numberError" className="text-danger"></div>
                                            <input className="form-control mt-3" type="link" placeholder="Youtube Link" name="youtube" value={Teacher.youtube} onChange={onchange} />
                                            <input className="form-control mt-3" type="link" placeholder="Fb Profile Link" name="fbUrl" value={Teacher.fbUrl} onChange={onchange} />

                                        </div>

                                        <div className="col-md-6">
                                            <input className="form-control mt-3" type="email" placeholder="Email" name="email" value={Teacher.email} onChange={onchange} />
                                            <div id="emailError" className="text-danger"></div>
                                            <input className="form-control mt-3" type="text" placeholder="Website Url" name="website" value={Teacher.website} onChange={onchange} />
                                            <input className="form-control mt-3" type="file" placeholder="Website Url" name="image" onChange={onchange} />
                                            <input className="form-control mt-3" type="link" placeholder="twitter Profile Link" name="twitterUrl" value={Teacher.twitterUrl} onChange={onchange} />
                                            <input className="form-control mt-3" type="link" placeholder="Insta Profile Link" name="instaUrl" value={Teacher.instaUrl} onChange={onchange} />
                                        </div>

                                        <div className="col-md-6">
                                            <textarea rows={10} className="form-control mt-3" type="text" placeholder="Qualifications" name="qualification" value={Teacher.qualification} onChange={onchange} />
                                            <div id="qualiError" className="text-danger"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <textarea rows={10} className="form-control mt-3" type="text" placeholder="Experience" name="experience" value={Teacher.experience} onChange={onchange} />
                                            <div id="expeError" className="text-danger"></div>
                                        </div>

                                        <div className="col-md-12">
                                            <textarea className="form-control mt-3" name="description" id="" cols="30" rows="10" placeholder="Description" value={Teacher.description} onChange={onchange}></textarea>
                                            <div id="descriptionError" className="text-danger"></div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary mt-3 px-3">Add teacher</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <TeacherModals />
        </div>
    )
}