import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, Outlet, useNavigate } from 'react-router-dom';

function UserProfileTabs() {
  const [activeTab, setActiveTab] = useState("courses");
  const [currentPage, setCurrentPage] = useState("user-courses");
  const navigate = useNavigate();

  const selectTab = (tabKey) => {
    setActiveTab(tabKey);
    // Reset the current page to the default for the new tab
    setCurrentPage(defaultRoutes[tabKey]);
  };

  // Define default routes for each tab
  const defaultRoutes = {
    courses: "user-courses",
    instructor: "instructor-registration",
    school: "register-school"
  };

  useEffect(() => {
    // Navigate to the current page of the active tab
    navigate(currentPage);
  }, [currentPage, navigate]);

  return (
    <section className="mt-3">
      <div className="container">
        <Tabs
          activeKey={activeTab}
          id="fill-tab-example"
          className="mb-3 custom-tabs"
          fill
          onSelect={selectTab}
        >
          <Tab eventKey="courses" title="Courses">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="outlet-buttons">
                      <Link to="user-courses" onClick={() => setCurrentPage("user-courses")}>
                        <button className="btn-1 profile-page-btns user-profile-courses-btn" style={{ display: 'block' }}>
                          All Courses
                        </button>
                      </Link>
                      <Link to="user-enrolled-courses" onClick={() => setCurrentPage("user-enrolled-courses")}>
                        <button className="btn-2 profile-page-btns user-profile-courses-btn" style={{ display: 'block' }}>
                          Enrolled Courses
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Outlet />
                  </div>
                </div>
              </div>
            </section>
          </Tab>
          <Tab eventKey="instructor" title="Instructor Registration">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="outlet-buttons">
                      <Link to="instructor-registration" onClick={() => setCurrentPage("instructor-registration")}>
                        <button className="btn-1 profile-page-btns">
                          Register As Instructor
                        </button>
                      </Link>
                      <Link to="registration-details" onClick={() => setCurrentPage("registration-details")}>
                        <button className="btn-2 profile-page-btns">
                          Registration Details
                        </button>
                      </Link>
                      <Link to="instructor-course" onClick={() => setCurrentPage("instructor-course")}>
                        <button className="btn-2 profile-page-btns">
                          Instructor Course
                        </button>
                      </Link>
                      <Link to="instructor-lessons" onClick={() => setCurrentPage("instructor-lessons")}>
                        <button className="btn-2 profile-page-btns">
                          Instructor Lessons
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Outlet />
                  </div>
                </div>
              </div>
            </section>
          </Tab>
          <Tab eventKey="school" title="School Registration">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="outlet-buttons">
                      <Link to="register-school" onClick={() => setCurrentPage("register-school")}>
                        <button className="btn-1 profile-page-btns">
                          School Registration
                        </button>
                      </Link>
                      <Link to="school-registration-details" onClick={() => setCurrentPage("school-registration-details")}>
                        <button className="btn-2 profile-page-btns">
                          View Your Schools
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Outlet />
                  </div>
                </div>
              </div>
            </section>
          </Tab>
        </Tabs>
      </div>
    </section>
  );
}

export default UserProfileTabs;
