import React, { useContext, useState } from "react"
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import userContext from "../../contexts/UseContext";
import UserModals from "./UserModals";

export default function Adduser() {
    const { allUsers, deleteUserId, getUserId, getAllUsers } = useContext(userContext)
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false)
    const [searchOption, setSearchOption] = useState("")

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: "user",
        number: ""
    })

    const nameError = document.getElementById("nameError")
    const emalError = document.getElementById("emailError")
    const pasError = document.getElementById("passwordError")
    const CPasError = document.getElementById("CPasswordError")
    const numberError = document.getElementById("numberError")
    const missingFieldsError = (fields) => {
        let emptyFieldError = false
        if (!fields.name) {
            nameError.innerText = "please enter email"
            emptyFieldError = true
        } else {
            nameError.innerText = ""
        }
        if (!fields.email) {
            emalError.innerText = "please enter email"
            emptyFieldError = true
        } else if (!fields.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emalError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emalError.innerText = ""
        }
        if (!fields.password) {
            pasError.innerText = "please enter password"
            emptyFieldError = true
        } else {
            pasError.innerText = ""
        }
        if (!fields.confirmPassword) {
            CPasError.innerText = "please enter confirm password"
            emptyFieldError = true
        } else {
            CPasError.innerText = ""
        }
        if (!fields.number) {
            numberError.innerText = "please enter Number"
            emptyFieldError = true
        } else {
            numberError.innerText = ""
        }
        return emptyFieldError;
    }
    const addUser = async (e) => {
        e.preventDefault()
        const { email, password, confirmPassword, role, name, number } = user
        const hasError = missingFieldsError(user)
        if (hasError) {
            return;
        }
        const res = await fetch("https://accademia-backend.vercel.app/api/user/adduser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, password, confirmPassword, role, name, number })
        })
        const data = await res.json()
        const Error = document.getElementById("error")
        if (data.message === "user with this email already exists") {
            emalError.innerText = "user with this email already exists"
        } else if (data.message === "This number already exists") {
            numberError.innerText = "This number already exists"
        } else if (data.message === "Password does not match") {
            CPasError.innerText = "Password does not match"
        } else {
            Error.innerText = ""
            setUser({
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
                role: "",
                number: ""
            })
            Swal.fire({
                position: "center",
                icon: "success",
                title: "User has been created",
                showConfirmButton: false,
                timer: 1500
            })
            getAllUsers()
        }
    }

    const onchange = (e) => {
        setUser((user) => ({ ...user, [e.target.name]: e.target.value }));
    };

    // pagination process
    const [pageNumber, setPageNumber] = useState(1)
    const userPerPage = 16;
    const lastUserIndex = pageNumber * userPerPage
    const firstUserIndex = lastUserIndex - userPerPage
    const allUser = allUsers && allUsers.filter(user => user.name.toLowerCase().includes(searchOption.toLowerCase()) || user.role.toLowerCase().includes(searchOption.toLowerCase()) || user.email.toLowerCase().includes(searchOption.toLowerCase())).slice(firstUserIndex, lastUserIndex)

    const paginate = (pagNumber) => {
        setPageNumber(pagNumber)
    }

    return (
        <div className="container mt-3">
            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-8">
                    <input type="text" className="form-control" value={searchOption} onChange={(e) => setSearchOption(e.target.value)} placeholder="Search By name/email/role" />
                </div>
                <div className="d-flex justify-content-end col-md-7 col-4">
                    <button className="btn btn-primary mb-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="fas fa-plus"></i> Add User</button>
                </div>
                <div className="col-md-11">
                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUser && allUser.map((user) => {
                                    return <tr>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={() => getUserId(user._id)}></i>
                                            <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" onClick={() => getUserId(user._id)}></i>
                                            <i className="fa fa-trash" onClick={() => deleteUserId(user._id)}></i>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                    {[...Array(Math.ceil(allUsers.length / userPerPage)).keys()].map((number) => {
                        return <button key={number + 1} className="btn btn-primary mx-2" onClick={() => paginate(number + 1)}>{number + 1}</button>
                    })}
                </div>
            </div>

            {/* {/ user modal /} */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addUser}>
                                <div id="error" className="text-danger text-center"></div>
                                <input className="form-control mt-3" name="name" value={user.name} onChange={onchange} type="name" placeholder="Name" />
                                <div id="nameError" className="text-danger"></div>
                                <input className="form-control mt-3" name="email" value={user.email} onChange={onchange} type="email" placeholder="Email" />
                                <div id="emailError" className="text-danger"></div>
                                <div className="d-flex align-items-center mt-3" style={{ border: "1px solid #d7d7d7", borderRadius: "6px" }}>
                                    <input className="form-control border-0" name="password" value={user.password} onChange={onchange} type={showPassword ? "text" : "password"} placeholder="Password" />
                                    <i className="fas fa-eye mx-2" onClick={toggleShowPassword}></i>
                                </div>
                                <div id="passwordError" className="text-danger"></div>
                                <div className="d-flex align-items-center mt-3" style={{ border: "1px solid #d7d7d7", borderRadius: "6px" }}>
                                    <input className="form-control border-0" name="confirmPassword" value={user.confirmPassword} onChange={onchange} type={showPassword1 ? "text" : "password"} placeholder="Confirm Password" />
                                    <i className="fas fa-eye mx-2" onClick={toggleShowPassword1}></i>
                                </div>
                                <div id="CPasswordError" className="text-danger"></div>

                                <PhoneInput
                                    className="mt-3 phonInput"
                                    inputStyle={{
                                        border: "none",
                                        boxShadow: "none"
                                    }}
                                    country={'pk'}
                                    name="number"
                                    value={user.number}
                                    onChange={(value, country, event, formattedValue) => {
                                        onchange({
                                            target: {
                                                name: 'number',
                                                value: formattedValue
                                            }
                                        });
                                    }}
                                />
                                <div id="numberError" className="text-danger"></div>

                                <button type="submit" className="btn btn-primary mt-3">Add</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <UserModals />
        </div>
    )
}