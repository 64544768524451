import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import LessonContext from '../../contexts/LessonContext';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import MyContext from '../../contexts/Mycontexts';

export default function AllLessons() {
    const { lessons, getById, lessonById, setLessonById, delById, allLessons } = useContext(LessonContext)
    const { signUser } = useContext(MyContext)
    const [updateLessons, setUpdateLessons] = useState([])
    const [lgShow, setLgShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const { pathname } = useLocation()
    const updateLesson = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });
        if (isConfirmed) {
            const { title, description, videoUrls } = lessonById;
            const res = await fetch(`https://accademia-backend.vercel.app/api/lesson/update/${lessonById._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ title, description, videoUrls })
            })
            const data = await res.json()
            console.log(data);
            allLessons()
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
    }
    const onchange = (e, index) => {
        if (e.target.name === "videoUrls") {
            const updateVideoUrls = [...lessonById.videoUrls]
            updateVideoUrls[index] = e.target.value
            setLessonById({ ...lessonById, videoUrls: updateVideoUrls })
        } else {
            setLessonById({ ...lessonById, [e.target.name]: e.target.value })
        }
    }

    const lessonsData = () => {
        if (pathname === "/user-panel/instructor-lessons") {
            const allLessons = lessons && lessons.filter(lesson => lesson.courseId && lesson.courseId.userId === signUser._id)
            console.log(allLessons);
            setUpdateLessons(allLessons || [])
        } else {
            setUpdateLessons(lessons)
        }
    }

    useEffect(() => {
        lessonsData();
    }, [pathname, lessons]);

    const openModalViewLesson = (id) => {
        getById(id)
        setLgShow(true)
    }
    const openModalEditLesson = (id) => {
        getById(id)
        setEditShow(true)
    }
    return (
        <>
            <div className="col-md-11">
                <div className="table-container" style={{ overflowX: "auto" }}>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Course Title</th>
                                <th scope="col">Lesson Title</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {updateLessons.map((lesson, index) => {
                                return <tr key={index}>
                                    <td>{lesson.courseId && lesson.courseId.title}</td>
                                    <td>{lesson.title}</td>
                                    <td><i className="fa fa-eye me-2" onClick={() => openModalViewLesson(lesson._id)} ></i>
                                        <i className="fa fa-pen me-2" onClick={() => openModalEditLesson(lesson._id)}></i>
                                        <i className="fa fa-trash" onClick={() => delById(lesson._id)}></i>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* {/ view modal /} */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        View Course Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="error" className="text-danger text-center"></div>
                        <label htmlFor="name" className="mt-3">Lesson Title</label>
                        <input className="form-control" value={lessonById.title} id="name" type="name" placeholder="Name" />
                        <label htmlFor="email" className="mt-3">Description</label>
                        <textarea cols="30" rows="10" className='form-control' value={lessonById.description}></textarea>

                        <label htmlFor="number" className="mt-3">Youtube Video Urls</label>
                        {lessonById.videoUrls && lessonById.videoUrls.map((url, index) => {
                            return <input key={index} className="form-control mb-3" value={url} />
                        })}
                    </form>
                </Modal.Body>
            </Modal>

            {/* {/ Edit modal /} */}
            <Modal
                size="lg"
                show={editShow}
                onHide={() => setEditShow(false)}
                aria-labelledby="example-modal-sizes-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title">
                        Edit Course Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="error" className="text-danger text-center"></div>
                        <label htmlFor="name" className="mt-3">Lesson Title</label>
                        <input className="form-control" value={lessonById.title} name='title' onChange={onchange} />
                        <label htmlFor="email" className="mt-3">Description</label>
                        <textarea cols="30" rows="10" className='form-control' value={lessonById.description} name='description' onChange={onchange}></textarea>

                        <label htmlFor="number" className="mt-3">Youtube Video Urls</label>
                        {lessonById.videoUrls && lessonById.videoUrls.map((url, index) => {
                            return <input key={index} className="form-control mb-3" name='videoUrls' value={url} onChange={(e) => onchange(e, index)} />
                        })}
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary' onClick={updateLesson}>Update Lesson</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
