import React from 'react'
import Userinfo from './Userinfo'
import PlatformIntro from './PlatformIntro'
import UserProfileTabs from './UserProfileTabs'

function UserProfilePage() {
  return (
    <div>
      <Userinfo />
      <PlatformIntro />
      <UserProfileTabs />
    </div>
  )
}

export default UserProfilePage
