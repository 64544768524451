import { React, useState, useEffect } from 'react'
import Swal from "sweetalert2";
import SchoolContext from './SchoolContext';

export default function SchoolProvider({ children }) {

    const [allSchool, setAllSchool] = useState([])
    const [getSchool, setGetSchool] = useState([])

    // get all Schools
    const allSchools = async () => {
        await fetch("https://accademia-backend.vercel.app/api/school/getAllSchools", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setAllSchool(data))
    }
    // get School id
    const schoolId = async (id) => {
        await fetch(`https://accademia-backend.vercel.app/api/school/getSchool/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setGetSchool(data))
    }

    // delete School
    const deleteSchool = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your news has been deleted.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(`https://accademia-backend.vercel.app/api/school/deleteSchool/${id}`, {
                method: "delete"
            })
            allSchools()
        }
    }

    useEffect(() => {
        allSchools()

    }, [])

    return (
        <SchoolContext.Provider value={
            { deleteSchool, allSchool, getSchool, schoolId, allSchools, setGetSchool }
        }>
            {children}
        </SchoolContext.Provider>
    )
}
