import React, { useContext } from "react";
import MyContext from "../contexts/Mycontexts";

export default function Instructor() {
    const { signUser } = useContext(MyContext);

    return (

        <div className="profile-position" >
            <section>
                <div className="head-bg">
                    <div className="head-overlay">
                        <h1 className="text-center py-6 text-white">Welcome {signUser && signUser.name}</h1>
                    </div>
                </div>
            </section>
        </div>
    )
}