import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import InstructorContext from '../../contexts/InstructorContext';
import { Button, Modal } from 'react-bootstrap';
import CategoryContext from '../../contexts/CategoryContext';
import CourseContext from '../../contexts/CourseContext';
import approvalWait from '../../img/approval-wait.avif'
import InstructorCourse from './InstructorCourse';
import MyContext from '../../contexts/Mycontexts';

export default function InstructorAddCourse() {
    const { techerApprove, TeacherApprovedFn } = useContext(InstructorContext)
    const { allCourses, checkCourse } = useContext(CourseContext);
    const { signUser } = useContext(MyContext)
    const { category } = useContext(CategoryContext)
    const [course, setCourse] = useState({
        title: "", duration: "", level: "", description: "", image: "", categoryId: "", userId: "", content: "", learning: "", moduleName1: "", moduleName2: "", instructorName: "",
    });

    const [lgShow, setLgShow] = useState(false);
    const validationFields = (fields) => {
        const titleError = document.getElementById("titleError");
        const categoryError = document.getElementById("categoryError");
        const durationError = document.getElementById("durationError");
        const levelError = document.getElementById("levelError");
        const descriptionError = document.getElementById("descriptionError");
        const learningError = document.getElementById("learningError");
        const instructorError = document.getElementById("instructorError");
        let emptyFieldError = false;
        if (!fields.categoryId) {
            categoryError.innerText = "please enter category";
            emptyFieldError = true;
        } else {
            categoryError.innerText = "";
        }
        if (!fields.title) {
            titleError.innerText = "please enter title";
            emptyFieldError = true;
        } else {
            titleError.innerText = "";
        }
        if (!fields.duration) {
            durationError.innerText = "please enter duration";
            emptyFieldError = true;
        } else {
            durationError.innerText = "";
        }
        if (!fields.level) {
            levelError.innerText = "please confirm level";
            emptyFieldError = true;
        } else {
            levelError.innerText = "";
        }
        if (!fields.description) {
            descriptionError.innerText = "please add description";
            emptyFieldError = true;
        } else {
            descriptionError.innerText = "";
        }

        if (!fields.instructorName) {
            instructorError.innerText = "Instructor Name Missing";
            emptyFieldError = true;
        } else {
            learningError.innerText = "";
        }
        return emptyFieldError;
    };
    // add course
    const addCourse = async (e) => {
        e.preventDefault();
        const { title, duration, level, description, image, categoryId, content, learning, moduleName1, moduleName2, instructorName, days, timeSlot,
        } = course;
        const hasError = validationFields(course);
        if (hasError) {
            return;
        }
        const formData = new FormData();
        formData.append("title", title);
        formData.append("duration", duration);
        formData.append("level", level);
        formData.append("userId", signUser._id);
        formData.append("description", description);
        formData.append("image", image);
        formData.append("categoryId", categoryId);
        formData.append("learning", learning);
        formData.append("content", content);
        formData.append("instructorName", instructorName);
        formData.append("moduleName1", moduleName1);
        formData.append("moduleName2", moduleName2);
        formData.append("days", days);
        formData.append("timeSlot", timeSlot);

        const res = await fetch(
            "https://accademia-backend.vercel.app/api/course/addcourse",
            {
                method: "POST",
                body: formData,
            }
        );
        const data = await res.json();
        if (!res.ok) {
            console.log(`Error: ${res.status} - ${res.statusText}`);
        }
        allCourses();
        checkCourse()
        setCourse({
            title: "", duration: "", level: "", description: "", image: "", categoryId: "", learning: "", content: "", moduleName1: "", moduleName2: "", instructorName: "", days: "", timeSlot: "",
        });
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Course has been created",
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const onchange = (e) => {
        if (e.target.files) {
            setCourse({ ...course, image: e.target.files[0] });
        } else {
            setCourse({ ...course, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        TeacherApprovedFn()
    }, [])

    const noRequest = techerApprove.length === 0
    const notApproved = techerApprove.filter(teacher => teacher.status === "Not Approved" || (teacher.status === "Rejected"))
    const Approved = techerApprove.filter(teacher => teacher.status === "Approved")

    if (noRequest || notApproved.length > 0) {
        return <div className="container request-approval">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-4">
                    <img src={approvalWait} alt="wait-for-approval" className="img-fluid wiat-img" />
                </div>
                <div className="col-md-8">
                    <div className="section-header">
                        <h2>You can add courses once you're approved as instructor</h2>
                    </div>
                    <p>You will be able to manage your course after accepted request.</p>
                </div>
            </div>

        </div>
    } else if (Approved.length > 0) {
        return (
            <>

                <div
                    className="d-flex justify-content-end"
                    style={{ marginRight: "15px" }}
                >
                    <Button onClick={() => setLgShow(true)}>Add Course</Button>
                </div>

                <InstructorCourse />
                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Course Modal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={addCourse} encType="multipart/form-data">
                            <div className="row">
                                <div id="error" className="text-danger text-center"></div>
                                <div className="col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Course Title"
                                        name="title"
                                        value={course.title}
                                        onChange={onchange}
                                    />
                                    <div id="titleError" className="text-danger"></div>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Instructor Name"
                                        name="instructorName"
                                        value={course.instructorName}
                                        onChange={onchange}
                                    />
                                    <div id="instructorError" className="text-danger"></div>
                                </div>

                                <div className="col-md-6">
                                    <input
                                        className="form-control mt-3"
                                        type="text"
                                        placeholder="Course Duration"
                                        name="duration"
                                        value={course.duration}
                                        onChange={onchange}
                                    />
                                    <div id="durationError" className="text-danger"></div>
                                    <select
                                        className="form-control mt-3"
                                        id="course level"
                                        name="level"
                                        value={course.level}
                                        onChange={onchange}
                                    >
                                        <option value="">Select Course Level</option>
                                        <option value="beginner">Beginner</option>
                                        <option value="intermediate">Intermediate</option>
                                        <option value="advanced">Advanced</option>
                                    </select>
                                    <div id="levelError" className="text-danger"></div>
                                </div>
                                <div className="col-md-6">
                                    <select
                                        className="form-control mt-3"
                                        id="course level"
                                        name="categoryId"
                                        value={course.categoryId}
                                        onChange={onchange}
                                    >
                                        <option value="">Select Category</option>
                                        {category &&
                                            category.map((data) => {
                                                return (
                                                    <option value={data._id}>{data.category}</option>
                                                );
                                            })}
                                    </select>
                                    <div id="categoryError" className="text-danger"></div>
                                    <input
                                        className="form-control mt-3"
                                        type="file"
                                        placeholder="Image"
                                        name="image"
                                        onChange={onchange}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="" className="mt-4 mb-1">
                                        <b>Class Schedule In A Week</b>
                                    </label>
                                    <input
                                        className="form-control mt-3"
                                        type="text"
                                        placeholder="Separate days with | sign e.g Monday | Tuesday"
                                        name="days"
                                        value={course.days}
                                        onChange={onchange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="" className="mt-4 mb-1">
                                        <b>Time Slots</b>
                                    </label>
                                    <input
                                        className="form-control mt-3"
                                        type="text"
                                        placeholder="5:00PM To 7:00PM"
                                        name="timeSlot"
                                        value={course.timeSlot}
                                        onChange={onchange}
                                    />
                                </div>

                                <div className="course-detail-title text-center mt-5 mb-3">
                                    <h2>Course Content Details</h2>
                                    <p style={{ fontSize: "14px" }}>
                                        In the following given fields of Module Name, you can
                                        add title of your choice (any title can be given like
                                        'Course Contents' or 'Learning Outcomes'). The boxes
                                        under Module Name fields are for details about the title
                                        you give. Add one detail per line, and use corresponding
                                        box for each field (right box for right field and same
                                        for left box).
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        className="form-control mt-3"
                                        type="text"
                                        placeholder="Module Name 1"
                                        name="moduleName1"
                                        value={course.moduleName1}
                                        onChange={onchange}
                                    />
                                    <textarea
                                        className="form-control mt-3"
                                        cols="30"
                                        rows="10"
                                        placeholder="Learning Outcomes (Write one outcome per line)"
                                        name="learning"
                                        value={course.learning}
                                        onChange={onchange}
                                    ></textarea>
                                    <div id="learningError" className="text-danger"></div>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        className="form-control mt-3"
                                        type="text"
                                        placeholder="Module Name 2"
                                        name="moduleName2"
                                        value={course.moduleName2}
                                        onChange={onchange}
                                    />
                                    <textarea
                                        className="form-control mt-3"
                                        cols="30"
                                        rows="10"
                                        placeholder="Course Contents (Write one content per line)"
                                        name="content"
                                        value={course.content}
                                        onChange={onchange}
                                    ></textarea>
                                    <div id="contentError" className="text-danger"></div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-12">
                                    <textarea
                                        className="form-control mt-3"
                                        cols="30"
                                        rows="10"
                                        placeholder="Course Description"
                                        name="description"
                                        value={course.description}
                                        onChange={onchange}
                                    ></textarea>
                                    <div id="descriptionError" className="text-danger"></div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-3 text-center px-4 py-2"
                                    >
                                        Add Course
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}
