import React from 'react'
import { useLocation } from 'react-router-dom'

export default function TextComponent() {
    const { pathname } = useLocation()
    if (pathname === "/") {
        return (
            <>
                <div className="row hidden-text mt-5">
                    <p>CapoBiz is a powerful retail software solution designed to help businesses grow and thrive in a competitive market. By providing tools that streamline operations, enhance customer experiences, and boost sales, CapoBiz empowers retail businesses to reach their full potential with ease.</p>
                    <p>Managing inventory, tracking sales, and overseeing customer data can be time-consuming, but CapoBiz simplifies these tasks with intuitive features. Our software automates core retail functions, enabling you to focus on growing your business while reducing manual effort and errors.</p>
                    <p>CapoBiz provides advanced analytics and reporting tools that give you valuable insights into your sales performance and customer behavior. Use this data to make informed decisions, optimize your inventory, and develop marketing strategies that drive more revenue and foster business growth.</p>
                    <p>Whether you're a small shop or a large retail chain, CapoBiz offers customizable solutions to meet your specific needs. Our software adapts to the size and scope of your business, providing features like multi-location management, loyalty programs, and detailed financial reporting, all designed to support your retail success.</p>
                </div>
            </>
        )
    }
    if (pathname === "/course") {
        return (
            <>
                <div className="row hidden-text mt-5">
                    <p>At Mentors Academia, we believe in empowering learners with the knowledge, skills, and confidence to excel in their chosen fields. Our course offerings are designed to cater to a wide variety of interests, from technology and business to creative arts and personal development. Whether you're just beginning your learning journey or you're a seasoned professional looking to enhance your skills, our diverse course catalog has something for everyone.
                    </p>
                    <p>
                        Each course is thoughtfully designed with a learner-centric approach, ensuring that you not only gain theoretical knowledge but also practical, hands-on experience. We understand the importance of real-world applications, which is why our courses are packed with interactive exercises, projects, and case studies. These elements help bridge the gap between learning and doing, making sure that what you learn is immediately applicable in your personal or professional life.
                    </p>
                    <p>
                        Our instructors are not just educators but industry experts who bring real-world insights to the virtual classroom. With years of experience in their respective fields, they provide mentorship and guidance beyond traditional learning. At Mentors Academia, we encourage active participation, where learners can ask questions, engage in discussions, and collaborate with peers from around the globe. This interaction helps build a community of like-minded learners and professionals, enhancing the overall educational experience.
                    </p>
                    <p>
                        We also recognize the varying schedules and commitments of our students, which is why our courses are designed with flexibility in mind. Whether you prefer learning at your own pace with self-guided modules or enjoy the structure of live sessions, Mentors Academia offers both. Our platform is accessible from any device, ensuring that learning can happen anytime, anywhere.
                    </p>
                    <p>
                        Moreover, at the completion of each course, learners receive a certificate that is recognized across industries, adding significant value to their professional profiles. With a commitment to high-quality education, Mentors Academia continues to innovate, ensuring that our courses meet the evolving demands of the modern world. Join us today and take the next step in your educational journey with courses that inspire, challenge, and transform.</p>
                </div>
            </>
        )
    }
    if (pathname === "/teacher") {
        return (
            <>
                <div className="row hidden-text mt-5">
                    <p>At Mentors Academia, we take immense pride in our community of educators who are the backbone of our learning platform. Our teachers are not just instructors—they are mentors, guides, and experts with a passion for teaching and a dedication to student success. Each of our instructors brings years of experience in their respective fields, ensuring that the content delivered is not only academically robust but also grounded in practical, real-world application.
                    </p>
                    <p>
                        Our platform thrives on the diverse expertise of our teachers, who come from a wide range of industries and academic backgrounds. From technology and business to arts and sciences, our educators are leaders in their fields. They understand the ever-evolving nature of their subjects and continuously update course content to reflect the latest trends, tools, and practices. This ensures that learners at Mentors Academia are always ahead of the curve, equipped with cutting-edge knowledge and skills.
                    </p>
                    <p>
                        What sets our teachers apart is their approach to education. At Mentors Academia, teaching goes beyond lectures and assignments—it's about fostering an environment where students feel supported, encouraged, and motivated to succeed. Our teachers actively engage with learners, offering personalized feedback, answering queries, and providing real-world insights that go beyond the textbooks. This mentorship extends into career guidance, where teachers help students navigate their professional paths by offering advice based on their own industry experiences.
                    </p>
                    <p>
                        Our educators are deeply committed to creating an inclusive learning environment where all students, regardless of their background or skill level, feel welcomed and valued. They understand that every student learns differently and are skilled in adapting their teaching methods to meet individual needs. Whether you're a beginner or an advanced learner, our teachers are here to support you every step of the way.
                    </p>
                    <p>
                        Furthermore, our teachers are lifelong learners themselves, continuously honing their craft by staying updated with the latest educational practices and technological tools. At Mentors Academia, we ensure that our teachers have access to resources and training that help them provide the best possible learning experience for our students. Join our learning community today and benefit from the expertise, dedication, and passion that our teachers bring to every course.</p>
                </div>
            </>
        )
    }
    if (pathname === "/school") {
        return (
            <>
                <div className="row hidden-text mt-5">
                    <p>At Mentors Academia, we believe that collaboration with schools is key to creating a transformative educational experience. We partner with forward-thinking schools and educational institutions to provide students with unparalleled access to high-quality courses, expert instructors, and innovative learning methodologies. Our goal is to bridge the gap between traditional education and the demands of the modern world, empowering students to thrive in a fast-paced, ever-changing landscape.
                    </p>
                    <p>
                        Our partnership with schools goes beyond simple course offerings. We work closely with institutions to understand their unique needs and tailor our programs to complement their existing curricula. Whether a school is looking to enhance its technology courses, provide students with additional resources in arts or sciences, or offer career development modules, Mentors Academia has a wide range of solutions designed to meet those needs.
                    </p>
                    <p>
                        One of the key benefits of partnering with Mentors Academia is our ability to offer flexible, scalable learning solutions. Schools can integrate our platform into their existing systems seamlessly, allowing students to access courses anytime, anywhere. Our courses are designed to be adaptive, meaning they cater to different learning styles and paces, ensuring that every student has the opportunity to succeed.
                    </p>
                    <p>
                        We also focus on building a collaborative community where students from different schools can connect, learn together, and exchange ideas. This global network of learners fosters a rich, multicultural learning environment that prepares students for success in a diverse, interconnected world. With the support of our expert instructors and cutting-edge course materials, students not only gain academic knowledge but also develop critical thinking, problem-solving, and communication skills that are essential in today’s workplace.
                    </p>
                    <p>
                        Moreover, Mentors Academia provides schools with detailed reporting and analytics, allowing administrators and teachers to track student progress and outcomes. This data-driven approach helps schools identify areas where students excel and where additional support may be needed, ensuring that every student receives a personalized learning experience that caters to their strengths and challenges.
                    </p>
                    <p>
                        Our commitment to educational excellence and innovation makes us the ideal partner for schools seeking to enhance their programs and provide their students with the best possible learning experience. By collaborating with Mentors Academia, schools can ensure that their students are prepared for the future, equipped with the skills and knowledge they need to succeed in an increasingly competitive world.</p>
                </div>
            </>
        )
    }
}
