import React from "react"
import Slider from "react-slick";
import Studentimg from "../img/Simage.webp"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function StudentSay() {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: false
    };
    return (
        <div>
            <div className="container mt-4">
                <div className="row d-flex justify-content-between">
                    <div className="section-header text-center mb-4">
                        <h2>Student Reviews</h2>
                        <h3>What Our Students Say!</h3>
                    </div>
                    <div className="col-md-5">
                        <p style={{ fontWeight: "initial" }}>Discover the impact of Mentors Academia through the words of our students. Their experiences speak volumes about the transformative power of our platform. Discover the transformative journey that awaits you at Mentors Academia, where every success story is a testament to the enduring impact of mentorship and academic excellence. Your future begins here, and your narrative becomes an integral part of the Mentors Academia legacy.</p>
                    </div>
                    <div className='col-md-6'>
                        <Slider {...settings}>
                            <div className="slider-content">
                                <p style={{ backgroundColor: "#294F71", color: "white" }} className="p-3">The mentors at Accademia have been instrumental in my academic
                                    journey. Their guidance and support have not only helped me
                                    understand complex concepts but also inspired me to pursue
                                    excellence in my field. I'm grateful for the personalized
                                    attention.</p>
                                <div className="d-flex align-items-center justify-content-center student-img">
                                    <img src={Studentimg} alt="student-img" />
                                    <div className="px-2">
                                        <p style={{ margin: "0", fontWeight: "bold" }}>Client Name</p>
                                        <p>(Profession)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-content">
                                <p style={{ backgroundColor: "#F69133", color: "white" }} className="p-3">The instructors at Accademia have played a vital role in shaping my education. Their expertise and encouragement have helped me grasp difficult topics while motivating me to strive for success in my studies. I'm thankful for their customized guidance and the practical advice they offer.</p>
                                <div className="d-flex align-items-center justify-content-center student-img">
                                    <img src={Studentimg} alt="student-img" />
                                    <div className=" px-2">
                                        <p style={{ margin: "0", fontWeight: "bold" }}>Client Name</p>
                                        <p >(Profession)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-content">
                                <p style={{ backgroundColor: "#294F71", color: "white" }} className="p-3">Accademia's mentors have been key to my academic success. Their dedication and knowledge have clarified challenging concepts for me, and their encouragement has pushed me to excel in my studies. I appreciate the tailored support and valuable feedback they consistently provide.</p>
                                <div className="d-flex align-items-center justify-content-center student-img">
                                    <img src={Studentimg} alt="Vstudent-img" />
                                    <div className=" px-2">
                                        <p style={{ margin: "0", fontWeight: "bold" }}>Client Name</p>
                                        <p >(Profession)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-content">
                                <p className="p-3" style={{ backgroundColor: "#F69133", color: "white" }}>My academic progress has greatly benefited from the mentors at Accademia. Their teaching and constant support have helped me overcome academic hurdles and inspired me to aim higher in my field. I'm truly thankful for their focused assistance and invaluable suggestions.</p>
                                <div className="d-flex align-items-center justify-content-center student-img">
                                    <img src={Studentimg} alt="student-img" />
                                    <div className=" px-2">
                                        <p style={{ margin: "0", fontWeight: "bold" }}>Client Name</p>
                                        <p >(Profession)</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}