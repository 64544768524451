import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import CategoryContext from "../../contexts/CategoryContext";
import AddLessonModal from "../Lesson/AddLessonModal";
import LessonContext from "../../contexts/LessonContext";
import CourseModals from "./CourseModals"
import CourseContext from "../../contexts/CourseContext";

export default function Addcourse() {
  const { category, AllCategory } = useContext(CategoryContext);
  console.log(category);
  
  const { deleteCourse, CourseById, allCourse, courseCategory, allCourses } = useContext(CourseContext);
  const { setLessonCourseId } = useContext(LessonContext);

  const [searchOption, setSearchOption] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [course, setCourse] = useState({
    title: "", duration: "", level: "", description: "", image: "", categoryId: "", content: "", learning: "", moduleName1: "", moduleName2: "", instructorName: "", days: "", timeSlot: "",
  });

  const validationFields = (fields) => {
    const titleError = document.getElementById("titleError");
    const categoryError = document.getElementById("categoryError");
    const durationError = document.getElementById("durationError");
    const levelError = document.getElementById("levelError");
    const descriptionError = document.getElementById("descriptionError");
    const learningError = document.getElementById("learningError");
    const instructorError = document.getElementById("instructorError");
    let emptyFieldError = false;
    if (!fields.categoryId) {
      categoryError.innerText = "please enter category";
      emptyFieldError = true;
    } else {
      categoryError.innerText = "";
    }
    if (!fields.title) {
      titleError.innerText = "please enter title";
      emptyFieldError = true;
    } else {
      titleError.innerText = "";
    }
    if (!fields.duration) {
      durationError.innerText = "please enter duration";
      emptyFieldError = true;
    } else {
      durationError.innerText = "";
    }
    if (!fields.level) {
      levelError.innerText = "please confirm level";
      emptyFieldError = true;
    } else {
      levelError.innerText = "";
    }
    if (!fields.description) {
      descriptionError.innerText = "please add description";
      emptyFieldError = true;
    } else {
      descriptionError.innerText = "";
    }

    if (!fields.instructorName) {
      instructorError.innerText = "Instructor Name Missing";
      emptyFieldError = true;
    } else {
      learningError.innerText = "";
    }
    return emptyFieldError;
  };

  // add course
  const addCourse = async (e) => {
    e.preventDefault();
    const { title, duration, level, description, image, categoryId, content, learning, moduleName1, moduleName2, instructorName, days, timeSlot,
    } = course;
    const hasError = validationFields(course);
    if (hasError) {
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("duration", duration);
    formData.append("level", level);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("categoryId", categoryId);
    formData.append("learning", learning);
    formData.append("content", content);
    formData.append("instructorName", instructorName);
    formData.append("moduleName1", moduleName1);
    formData.append("moduleName2", moduleName2);
    formData.append("days", days);
    formData.append("timeSlot", timeSlot);

    const res = await fetch(
      "https://accademia-backend.vercel.app/api/course/addcourse",
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await res.json();
    console.log(data);
    if (!res.ok) {
      console.log(`Error: ${res.status} - ${res.statusText}`);
    }
    allCourses();
    setCourse({
      title: "",
      duration: "",
      level: "",
      description: "",
      image: "",
      categoryId: "",
      learning: "",
      content: "",
      moduleName1: "",
      moduleName2: "",
      instructorName: "",
      days: "",
      timeSlot: "",
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Course has been created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const onchange = (e) => {
    if (e.target.files) {
      setCourse({ ...course, image: e.target.files[0] });
    } else {
      setCourse({ ...course, [e.target.name]: e.target.value });
    }
  };

  // pagination implement
  const coursePerPage = 16;
  const lastCourseIndex = pageNumber * coursePerPage;
  const firstCourseIndex = lastCourseIndex - coursePerPage;
  const allCorse =
    allCourse &&
    allCourse
      .filter((course) =>
        course.title.toLowerCase().includes(searchOption.toLowerCase())
      )
      .filter((course) => course.categoryId.includes(searchCategory))
      .slice(firstCourseIndex, lastCourseIndex);

  const paginate = (pageNmber) => {
    setPageNumber(pageNmber);
  };
  return (
    <div className="container mt-3">
      <div className="row d-flex justify-content-center">
        <div className="col-md-3 col-6 mt-2">
          <input
            type="text"
            className="form-control"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            placeholder="Search By Title"
          />
        </div>
        <div className="col-md-3 col-6 mt-2">
          <select
            className="form-control"
            id="course level"
            value={searchCategory}
            onChange={(e) => setSearchCategory(e.target.value)}
          >
            <option value="">Search By Category</option>
            {AllCategory &&
              AllCategory.map((course) => {
                return <option value={course._id}>{course.category}</option>;
              })}
          </select>
        </div>
        <div className="d-flex justify-content-end col-md-5 col-6 mt-2">
          <button
            className="btn btn-primary mb-5"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <i className="fas fa-plus"></i> Add Course
          </button>
        </div>
        <div className="col-md-11">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Lesson</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allCorse &&
                  allCorse.map((course, index) => {
                    return (
                      <tr>
                        <td>{course.title}</td>
                        {courseCategory[index] && (
                          <td>{courseCategory[index].category}</td>
                        )}
                        <td>{course.duration}</td>
                        <td>
                        <AddLessonModal courseIdd={course._id}/>
                        </td>
                        <td>
                          <i
                            className="fa fa-eye me-2"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop1"
                            onClick={() => CourseById(course._id)}
                          ></i>
                          <i
                            className="fa fa-pen me-2"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop2"
                            onClick={() => CourseById(course._id)}
                          ></i>
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteCourse(course._id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end my-3">
            {[...Array(Math.ceil(allCourse.length / coursePerPage)).keys()].map(
              (number) => {
                return (
                  <button
                    key={number + 1}
                    className="btn btn-primary mx-1"
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </button>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* {/ course modal /} */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="container">
              <div className="modal-body">
                <form onSubmit={addCourse} encType="multipart/form-data">
                  <div className="row">
                    <div id="error" className="text-danger text-center"></div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Course Title"
                        name="title"
                        value={course.title}
                        onChange={onchange}
                      />
                      <div id="titleError" className="text-danger"></div>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Instructor Name"
                        name="instructorName"
                        value={course.instructorName}
                        onChange={onchange}
                      />
                      <div id="instructorError" className="text-danger"></div>
                    </div>

                    <div className="col-md-6">
                      <input
                        className="form-control mt-3"
                        type="text"
                        placeholder="Course Duration"
                        name="duration"
                        value={course.duration}
                        onChange={onchange}
                      />
                      <div id="durationError" className="text-danger"></div>
                      <select
                        className="form-control mt-3"
                        id="course level"
                        name="level"
                        value={course.level}
                        onChange={onchange}
                      >
                        <option value="">Select Course Level</option>
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                      </select>
                      <div id="levelError" className="text-danger"></div>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control mt-3"
                        id="course level"
                        name="categoryId"
                        value={course.categoryId}
                        onChange={onchange}
                      >
                        <option value="">Select Category</option>
                        {category &&
                          category.map((data) => {
                            return (
                              <option value={data._id}>{data.category}</option>
                            );
                          })}
                      </select>
                      <div id="categoryError" className="text-danger"></div>
                      <input
                        className="form-control mt-3"
                        type="file"
                        placeholder="Image"
                        name="image"
                        onChange={onchange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mt-4 mb-1">
                        <b>Class Schedule In A Week</b>
                      </label>
                      <input
                        className="form-control mt-3"
                        type="text"
                        placeholder="Separate days with | sign e.g Monday | Tuesday"
                        name="days"
                        value={course.days}
                        onChange={onchange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mt-4 mb-1">
                        <b>Time Slots</b>
                      </label>
                      <input
                        className="form-control mt-3"
                        type="text"
                        placeholder="5:00PM To 7:00PM"
                        name="timeSlot"
                        value={course.timeSlot}
                        onChange={onchange}
                      />
                    </div>

                    <div className="course-detail-title text-center mt-5 mb-3">
                      <h2>Course Content Details</h2>
                      <p style={{ fontSize: "14px" }}>
                        In the following given fields of Module Name, you can
                        add title of your choice (any title can be given like
                        'Course Contents' or 'Learning Outcomes'). The boxes
                        under Module Name fields are for details about the title
                        you give. Add one detail per line, and use corresponding
                        box for each field (right box for right field and same
                        for left box).
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control mt-3"
                        type="text"
                        placeholder="Module Name 1"
                        name="moduleName1"
                        value={course.moduleName1}
                        onChange={onchange}
                      />
                      <textarea
                        className="form-control mt-3"
                        cols="30"
                        rows="10"
                        placeholder="Learning Outcomes (Write one outcome per line)"
                        name="learning"
                        value={course.learning}
                        onChange={onchange}
                      ></textarea>
                      <div id="learningError" className="text-danger"></div>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control mt-3"
                        type="text"
                        placeholder="Module Name 2"
                        name="moduleName2"
                        value={course.moduleName2}
                        onChange={onchange}
                      />
                      <textarea
                        className="form-control mt-3"
                        cols="30"
                        rows="10"
                        placeholder="Course Contents (Write one content per line)"
                        name="content"
                        value={course.content}
                        onChange={onchange}
                      ></textarea>
                      <div id="contentError" className="text-danger"></div>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control mt-3"
                        cols="30"
                        rows="10"
                        placeholder="Course Description"
                        name="description"
                        value={course.description}
                        onChange={onchange}
                      ></textarea>
                      <div id="descriptionError" className="text-danger"></div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-primary mt-3 text-center px-4 py-2"
                      >
                        Add Course
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Lesson Modal */}

      
      <CourseModals />


    </div>
  );
}
