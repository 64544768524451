import React, { useEffect, useState } from 'react'
import userContext from './UseContext'
import Swal from 'sweetalert2'

export default function UserProvider({ children }) {
    const [allUsers, setAllUsers] = useState([])
    const [getUser, setGetUser] = useState([])

    // get all users
    const getAllUsers = async () => {
        await fetch("https://accademia-backend.vercel.app/api/user/allusers", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setAllUsers(data))
    }

    // get user id
    const getUserId = async (id) => {
        await fetch(`https://accademia-backend.vercel.app/api/user/getuser/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setGetUser(data))
    }
    // get user id
    const deleteUserId = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your news has been deleted.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(`https://accademia-backend.vercel.app/api/user/deleteuser/${id}`, {
                method: "delete"
            })
            getAllUsers()
        }
    }

    useEffect(() => {
        getAllUsers()
    },[])
    return (
        <userContext.Provider value={{ allUsers, getUser, deleteUserId, getUserId,getAllUsers,setGetUser }}>
            {children}
        </userContext.Provider>
    )
}
