import React from 'react'
import LOGO from '../img/logo.png'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Link, useLocation } from 'react-router-dom';

function Footer() {

    const location = useLocation();
    if (

        location.pathname === "/adminpanel" ||
        location.pathname === "/adminpanel/dashboard" ||
        location.pathname === "/adminpanel/users" ||
        location.pathname === "/adminpanel/course" ||
        location.pathname === "/adminpanel/teacher" ||
        location.pathname === "/adminpanel/school" ||
        location.pathname === "/adminpanel/courseenroll" ||
        location.pathname === "/adminpanel/category" ||
        location.pathname === "/adminpanel/acceptedRequest" ||
        location.pathname === "/adminpanel/rejectedRequest" ||
        location.pathname === "/adminpanel/teacherRequest" ||
        location.pathname === "/adminpanel/schoolRequest" ||
        location.pathname === "/adminpanel/rejectedTeacher" ||
        location.pathname === "/adminpanel/rejectedSchool" ||
        location.pathname === "/studentpage/enrollCourses" ||
        location.pathname === "/studentpage/studentcourse" ||
        location.pathname === "/schoolUser/registerdSchools" ||
        location.pathname === "/schoolUser/registerSchool" ||
        location.pathname === "/instructorUser/instructorRequest" ||
        location.pathname === "/instructorUser/instructorDetail" ||
        location.pathname === "/adminpane/users"

    ) {
        return;
    }
    return (
        <div>
            <div className="container"></div>
            <div className="pg-footer">
                <footer className="footer">
                    <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                        <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                    </svg>
                    <div className="footer-content">
                        <div className="footer-content-column">
                            <div className="footer-logo">
                                <div>
                                    <img src={LOGO} alt="online-learning-platform" style={{ width: "170px" }} />
                                </div>
                            </div>
                            <div className="footer-menu">
                                <p>In the vast expanse of the online world, Mentors Academia leading online learning platform, offering a unique educational Directory platform that harmoniously integrates Islamic teachings with science and Digital technology.</p>

                            </div>
                        </div>
                        <div className="footer-content-column footerMidCol">
                            <h3 className='text-white'>Subscribe</h3>
                            <p>Stay updated on how future of technology is shaping</p>
                            <div className='d-flex'>
                                <input type='text' className='form-control bg-transparent py-3' placeholder='Enter Your Email Here'></input>
                                <button className='btn btn-light px-4' style={{ marginLeft: "2%" }}>Submit</button>
                            </div>
                        </div>
                        <div className="footer-content-column mt-md-0 mt-4">
                            <h3 className='text-white'>Get In Touch</h3>
                            <div className="d-flex" >
                                <LocalPhoneIcon className='mt-2 fw-bold' style={{ fontSize: '20px', color: "var(--secondary-color)" }} />&nbsp; <p className="footer-call-to-action-link-wrapper" style={{ fontSize: '15px' }}> +923 111 122 144 </p>
                            </div>
                            <div className="footer-call-to-action d-flex" style={{ marginTop: '-1px' }} >
                                <EmailIcon className='mt-2' style={{ fontSize: '20px', color: "var(--secondary-color)" }} />&nbsp; <p className="footer-call-to-action-link-wrapper" style={{ fontSize: '15px' }}> info@technicmentors.com </p>
                            </div>
                            <h3 className='text-white mt-3'>Social Links</h3>
                            <div className="d-flex text-light align-items-center footer-icon mt-3">
                                <a href="https://www.facebook.com/MentorsAcademia" target="blank"> <i className="me-3 fab fa-facebook footer-social"></i> <span className="d-none">book</span></a>
                                <a href="https://twitter.com/Mentorsacademia" target="blank"> <i className="me-3 fab fa-twitter footer-social"></i><span className="d-none">tter</span></a>
                                <a href="https://www.instagram.com/mentorsacademia/" target="blank"> <i className="me-3 fab fa-instagram footer-social"></i><span className="d-none">gram</span></a>
                                <a href="https://www.linkedin.com/in/Mentorsacademia/" target="blank"> <i className="me-3 fab fa-linkedin footer-social"></i><span className="d-none">in</span></a>
                                <a href="https://www.youtube.com/@Mentorsacademia/" target="blank"> <i className="me-3 fab fa-youtube footer-social"></i><span className="d-none">tube</span></a>
                            </div>
                            {/* <p className='mt-4 text-white' style={{ fontSize: '17px' }}>Sign Up For Newsletter</p>
                            <div className='mt-3'>

                                <input type='text' className='form-control bg-white py-4' placeholder='Example@gmail.com' style={{ height: '40px' }}></input>
                                <button className='btn mt-3  px-4' style={{ backgroundColor: 'var(--secondary-color)', color: "#fff" }}>Submit</button>
                            </div> */}
                        </div>
                        {/* <div className="footer-content-column">
                            <div className="footer-menu">
                                <h2 className="footer-menu-name"> Quick Links</h2>
                                <ul id="menu-quick-links" className="footer-menu-list">
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link to="/signin">Instructor Registration</Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link to="/signin">School Registration</Link>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <Link to="/signin">Add Course</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div className="footer-copyright">
                        <div className="footer-copyright-wrapper">
                            <p className="footer-copyright-text">
                                &copy; 2024 <Link className='footer-copyright-link' to="/" > Mentors Academia </Link>. All Rights Reserved | Developed With Love By <a className='footer-copyright-link' href="https://technicmentors.com" target='blank'> Technic Mentors </a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer
